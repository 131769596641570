<template>
    <a href="https://wa.link/2icsee" target="_blank">
        <div class="contacto-flotante">
            <img src="@/assets/icon-whatsapp-white.svg" alt="" class="">
        </div>    
    </a>
</template>
<style lang="scss">
.contacto-flotante{
    background-color: #00b66c;
    position: fixed;
    z-index: 200;
    right: 20px;
    bottom: 75px;
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
    box-shadow: 0px 0px 17px 0px #adadad;
    img{
        filter: grayscale(1);
        width: 28px;
    }
    &:hover{
        opacity: .8;
    }
}
</style>