import router from './../router/index'

export const resetStore = async (state) => {
    // state.procesoActual = 'validar-documento';
    state.errors = [];
    state.messageError = null;
    state.loaderIsActive = false;
    state.documentoCliente = null;
    state.celularEnmascaradoCliente = null;
    state.celularCliente = null;
    state.porcentajeDescuentoProntopago = null;
    state.pagoMinimo = null;
    state.totalDeduda = null;
    state.clienteNombre = null;
    state.facturasProntopago = [];
    state.informacionEstadoCuenta = null;
    state.idsProntoPago = null;
    state.valorAbono = null;
    state.procesoAnterior = null;
    state.bancosPSE = [];
    state.pseCodeSelect = '';
    state.franquiciaTarjeta = '';
    state.respuestaTransaccion = {
        state_pol: 0,
        referencia_pago: '',
        referencia_transaccion: '',
        fecha: '',
        valor_total: 0,
        descripcion: '',
        entidad: '',
    };
    state.cuponEstrategiaReventa = null;
    state.showModalCuponEstrategiaReventa = false;
    state.showLogout = null;
    state.validar_documento = null;
    state.validar_celular = null;
    state.validar_envio_token_sms = null;
    state.validar_token_sms = null;
    state.showArrowBack = null;
    state.beneficioQuac = null;
    state.beneficioQuacSeleccionado = 0;
    state.comerciosCredito = [];
    state.comercioCredito = null;
    state.nitComercio = null;
};

export const agregarTiposDeDocumentos = async (state,typeDocuments) => {
    state.typeDocuments = typeDocuments;
};

export const setCurrentTypeDoc = async (state,currentTypeDoc) => {
    state.currentTypeDoc = currentTypeDoc;
};

export const setTempNit = async (state,nit) => {
    state.tempNit = nit
}

export const setTempVal = async (state,val) => {
    state.tempVal = val
}

export const cambiarProceso = async (state, proceso) => {
    state.procesoActual = proceso;
    state.errors = [];
    state.messageError = '';
    router.push({name: proceso});
};

export const agregarErrors = async (state,errors) => {
    const arrErrors = [];
    for (const prop in errors) {
        arrErrors.push(`${errors[prop][0]}`);
    }
    state.errors = arrErrors;
};

export const agregarMessageError = async (state,messageError) => {
    state.messageError = messageError;
};

export const cambiarEstadoLoader = async (state,status) => {
    state.loaderIsActive = status;
};

export const cambiarDocumentoCliente = async (state,documento) => {
    state.documentoCliente = documento;
};

export const cambiarCelularEnmascaradoCliente = async (state,celular) => {
    state.celularEnmascaradoCliente = celular;
};

export const cambiarNombreCliente = async (state,nombre) => {
    state.clienteNombre = nombre.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
};

export const cambiarPorcentajeDescuentoProntopago = async (state,porcentaje) => {
    state.porcentajeDescuentoProntopago = porcentaje;
};

export const cambiarPagoMinimo = async (state,pagoMinimo) => {
    state.pagoMinimo = pagoMinimo;
};

export const cambiarTotalDeuda = async (state,totalDeduda) => {
    state.totalDeuda = totalDeduda;
};

export const agregarFacturasProntopago = async (state,facturas) => {
    state.facturasProntopago = facturas;
};

export const agregarCelularCliente = async (state,celular) => {
    state.celularCliente = celular;
};

export const agregarInformacionEstadoCuenta = async (state,estadoCuenta) => {
    state.informacionEstadoCuenta = estadoCuenta;
};

export const agregarIdProntoPago = async (state,idsProntoPago) => {
    state.idsProntoPago = idsProntoPago;
};

export const agregarValorAbono = async (state,valorAbono) => {
    state.valorAbono = valorAbono;
};

export const agregarProcesoAnterior = async (state,procesoAnterior) => {
    state.procesoAnterior = procesoAnterior;
}

export const agregarBancosPSE = async (state,bancosPSE) => {
    state.bancosPSE = bancosPSE;
};

export const agregarFranquiciaTarjeta = async (state,franquiciaTarjeta) => {
    state.franquiciaTarjeta = franquiciaTarjeta;
};

export const agregarRespuestaTransaccion = async (state,respuesta) => {
    state.respuestaTransaccion.state_pol = respuesta.state_pol;
    state.respuestaTransaccion.referencia_pago = respuesta.transactionId;
    state.respuestaTransaccion.referencia_transaccion = respuesta.referenceCode;
    state.respuestaTransaccion.fecha = respuesta.fecha;
    state.respuestaTransaccion.valor_total = respuesta.tx_value;
    state.respuestaTransaccion.descripcion = respuesta.description;
    state.respuestaTransaccion.entidad = respuesta.paymentMethod;
    state.respuestaTransaccion.title = respuesta.status;
};

export const agregarCuponEstrategiaReventa = async (state,cuponEstrategiaReventa) => {
    state.cuponEstrategiaReventa = cuponEstrategiaReventa;
};

export const cambiarEstadoModalCuponEstrategiaReventa = async (state,status) => {
    state.showModalCuponEstrategiaReventa = status;
};

export const showLogout = async (state, status) => {
    state.showLogout = status;
};

export const gestionarValidarDocumento = async (state,status) => {
    state.validar_documento = status;
};

export const gestionarValidarCelular = async (state,status) => {
    state.validar_celular = status;
};

export const gestionarValidarEnvioTokenSMS = async (state,status) => {
    state.validar_envio_token_sms = status;
};

export const gestionarValidarTokenSMS = async (state,status) => {
    state.validar_token_sms = status;
};

export const gestionarShowArrowBack = async (state,status) => {
    state.showArrowBack = status;
};

export const volverAtras = async (state, route) => {
    router.replace({name: route});
};

export const agregarBeneficioQuac = async (state,beneficioQuac) => {
    state.beneficioQuac = beneficioQuac;
};

export const beneficioQuacSeleccionado = async (state,status) => {
    state.beneficioQuacSeleccionado = status;
};

export const agregarComerciosCredito = async (state,comercios) => {
    state.comerciosCredito = comercios;
};

export const agregarComercioCredito = async (state,comercio) => {
    state.comercioCredito = comercio;
};

export const agregarNitComercio = async (state,nitComercio) => {
    state.nitComercio = nitComercio;
};

export const changeViewLayout = async (state,status) => {
    state.viewLayout = status;
};

export const changeDataPaymentMethods = async  (state, data) => {
    state.dataPaymentMethods = data;
}
