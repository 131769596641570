<template>
    <div class="loader" v-if="loaderIsActive">
        <div class="loader-gif"></div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default{
    computed:{
        ...mapState(['loaderIsActive'])
    }
}
</script>
<style>
.loader{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.2);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
}
.loader img{
    max-width: 70px;
}
/* HTML: <div class="loader"></div> */
.loader .loader-gif {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #8524ba;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 70deg,#0000 71deg 90deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation: l5 1s infinite;
}
@keyframes l5 {to{transform: rotate(.5turn)}}
</style>