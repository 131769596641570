<template>
    <div>
      <div class="nav-menu">
        <div class="col-md col-4 d-flex justify-content-start p-0">
          <div class="container-logo">
            <img src="../assets/logo-quac2.svg">
          </div>
        </div>
        <div class="col title-header px-0">
          <h1>Paga tu cuota</h1>
        </div>
        <div class="col-md col-2 d-flex justify-content-end px-0">
          <div v-if="showLogout" class="d-flex align-items-center button-logout" @click="salir()" title="Salir">
            <img src="../assets/icon-close.svg">
          </div>
        </div>
      </div>
      <div class="breadcrumb" v-if="comerciosCredito.length > 0">
        <div class="router-page">
          <span>Inicio </span> <!--@click="$router.replace('seleccionar-comercio')"-->
          <span v-if="comercioCredito"> Comercio: <b>{{ comercioCredito.name }}</b></span>
        </div>
        <div class="content-info-user">
          <img src="../assets/icon-user.svg">
          <span>Hola {{ clienteNombre }}!</span>
        </div>
      </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
export default{
  computed:{
    ...mapState(['showLogout','showArrowBack', 'clienteNombre', 'comercioCredito', 'comerciosCredito']),
  },
  methods:{
    ...mapMutations(['cambiarProceso','resetStore','volverAtras']),
    salir(){
      this.resetStore();
      this.$router.replace({name: 'validar-documento'})
    },
  }
}
</script>
<style lang="scss" scoped>
.nav-menu {
  padding: 1rem;
  .container-logo img {
    min-width: 100px;
  }
  .title-header h1 {
    font-size: 28px;
    color: #7633FF;
    text-align: center;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    .title-header h1 {
      font-size: 20px;
    }
  }
}
.breadcrumb {
  margin: 1rem;
  padding: .5rem 2rem;
  border-radius: 6px;
  background-color: #F3F3F8;
  display: flex;
  justify-content: space-between;
  .router-page {
    span {
      color: #7633FF;
      cursor: pointer;
      &::after {
        content: '/';
        cursor: default;
      }
      &:last-child {
        cursor: default;
        &::after {
          content: '';
        }
      }
    }
  }
  .content-info-user {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      color: #2E1380;
    }
  }
}
</style>