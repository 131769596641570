import apiQuac from '@/api/apiQuac.js'
import $ from 'jquery'
import $router from '../router/index.js'

export const validarDocumento = async ({commit},payload) => {
    commit('cambiarEstadoLoader',true);
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    try{
        await apiQuac.post('/v1/credit/account-status/validate-document',
            {
                client_document: payload.doc,
                client_document_type: payload.typeDoc,
            }
        ).then((response) => {
            if(response.data.cupo){
                commit('gestionarValidarDocumento', true);
                commit('gestionarShowArrowBack', true);
                commit('cambiarDocumentoCliente', payload.doc);
                commit('cambiarCelularEnmascaradoCliente', response.data.celular);
                commit('cambiarProceso', 'validar-celular');
                commit('setCurrentTypeDoc', payload.typeDoc)
            }else{
                commit('cambiarDocumentoCliente',payload.doc);
                $("#modalUsuarioSinCredito").modal('show');
            }
            commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al consultar el documento. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const obtenerTiposDeDocumento = async ({commit}) => {
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    // commit('cambiarEstadoLoader',true);
    try{
        await apiQuac.get('/v1/credit/get-document-types').then((response) => {
            commit('agregarTiposDeDocumentos',response.data.document_types);
            // commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            // commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al obtener los bancos para pagar con PSE. Intentelo mas tarde.');
        // commit('cambiarEstadoLoader',false);
    }
}

export const validarCelular = async ({state, commit},payload) => {
    commit('cambiarEstadoLoader', true);
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    payload.client_document_type = state.currentTypeDoc
    try{
        await apiQuac.post('/v1/credit/account-status/validate-phone-payment',payload).then((response) => {
            if(response.data.cupo){
                if(response.data.validarCelular){
                    commit('gestionarValidarCelular',true);
                    commit('gestionarValidarDocumento',true);
                    commit('cambiarDocumentoCliente',payload.client_document);
                    commit('agregarCelularCliente',payload.client_phone);
                    commit('cambiarNombreCliente',response.data.nombre);
                    commit('agregarComerciosCredito',response.data.comercios);
                    commit('cambiarPorcentajeDescuentoProntopago',response.data.porcentaje_ahorro);
                    commit('changeDataPaymentMethods',response.data.payment_methods);
                    // commit('agregarFacturasProntopago',response.data.facturas);
                    commit('agregarBeneficioQuac',response.data.beneficioQuac);
                    state.errors = [];
                    state.messageError = '';
                    state.procesoActual = 'seleccionar-comercio';
                    $router.replace({name: 'seleccionar-comercio'});
                    commit('showLogout',1);
                }else{
                    $("#modalCelularInvalido").modal('show');
                }
            }else{
                commit('cambiarDocumentoCliente',payload.documento);
                $("#modalUsuarioSinCredito").modal('show');
            }
            commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al validar el celular. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const generarTokenEstadoCuenta = async ({commit},payload) => {
    commit('cambiarEstadoLoader',true);
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    try{
        await apiQuac.post('/v1/credit/account-status/generate-token',payload).then(() => {
            commit('gestionarValidarEnvioTokenSMS',true);
            commit('cambiarProceso','validar-identidad');
            commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al generar el token para consultar el estado de cuenta. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const reenviarTokenEstadoCuenta = async ({state, commit}) => {
    commit('cambiarEstadoLoader',true);
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    let payload = {
        celular: state.celularCliente,
        documento: state.documentoCliente,
        nit_business: state.comercioCredito.nitBusiness.toString()
    };
    try{
        return await apiQuac.post('/v1/credit/account-status/generate-token',payload).then(() => {
            commit('gestionarValidarEnvioTokenSMS',true);
            commit('cambiarEstadoLoader',false);
            return true;
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
            return false;
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al reenviar el token para consultar el estado de cuenta. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
        return false;
    }
}

export const validarTokenEstadoCuenta = async ({state, commit},payload) => {
    commit('cambiarEstadoLoader',true);
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    payload.client_document_type = state.currentTypeDoc
    try{
        await apiQuac.post('/v1/credit/account-status/validate-token',payload).then((response) => {
            if(response.data.validarIdentidad){
                commit('gestionarValidarTokenSMS',true);
                state.errors = [];
                state.messageError = '';
                $router.replace({name: 'estado-cuenta'});
                state.procesoActual = 'estado-cuenta';
                commit('agregarInformacionEstadoCuenta',response.data.cartera);
            }else{
                $("#modalCodigoInvalido").modal('show');
            }
            commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al validar el token para consultar el estado de cuenta. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const pagarCuotaBancolombia = async ({state, commit}) => {
    if(state.loaderIsActive) return;
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    if(state.valorAbono <= 0)
    {
        commit('agregarMessageError','El valor del abono debe ser mayor a $0');
        return;
    }
    commit('cambiarEstadoLoader',true);
    const payload = {
        'client_document': state.documentoCliente,
        'client_document_type': state.currentTypeDoc,
        'nit_business': state.comercioCredito.nitBusiness.toString(),
        'payment_value': state.valorAbono,
        'invoices': state.idsProntoPago,
        'payment_type': state.idsProntoPago ? '2' : '1',
        'channel_key': state.canal,
        'payment_request_reference': state.payment_request_reference
    };
    try{
        await apiQuac.post('/v1/payment/button-bancolombia',payload).then((response) => {
            commit('resetStore', '');
            commit('cambiarEstadoLoader',false);
            window.location.href = response.data;
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al generar el pago con Bancolombia. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const pagarCuotaEfecty = async ({state, commit}) => {
    if(state.loaderIsActive) return;
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    if(state.valorAbono < 20000)
    {
        commit('agregarMessageError','El valor mínimo de pago con efecty es de $20.000');
        return;
    }
    commit('cambiarEstadoLoader',true);
    const payload = {
        'client_document': state.documentoCliente,
        'client_document_type': state.currentTypeDoc,
        'nit_business': state.comercioCredito.nitBusiness.toString(),
        'payment_value': state.valorAbono,
        'invoices': state.idsProntoPago,
        'payment_type': state.idsProntoPago ? '2' : '1',
        'channel_key': state.canal,
        'payment_request_reference': state.payment_request_reference
    };
    try{
        await apiQuac.post('/v1/payment/cash',payload).then((response) => {
            commit('resetStore', '');
            commit('cambiarEstadoLoader',false);
            window.location.href = response.data;
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al generar el pago con efecty. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const obtenerBancosPSE = async ({state, commit}) => {
    if (state.bancosPSE.length) return
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    commit('cambiarEstadoLoader',true);
    const payload = {
        'nit_business': state.comercioCredito.nitBusiness.toString(),
    }
    try{
        await apiQuac.post('/v1/payment/get-banks', payload).then((response) => {
            commit('agregarBancosPSE',response.data);
            commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al obtener los bancos para pagar con PSE. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const pagarCuotaPSE = async ({state, commit},pseCodeSelect) => {
    if(state.loaderIsActive) return;
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    commit('cambiarEstadoLoader',true);
    const payload = {
        'bank_pse': pseCodeSelect,
        'client_document': state.documentoCliente,
        'client_document_type': state.currentTypeDoc,
        'nit_business': state.comercioCredito.nitBusiness.toString(),
        'payment_value': state.valorAbono,
        'invoices': state.idsProntoPago,
        'payment_type': state.idsProntoPago ? '2' : '1',
        'channel_key': state.canal,
        'payment_request_reference': state.payment_request_reference
    };
    try{
        await apiQuac.post('/v1/payment/pse',payload).then((response) => {
            commit('resetStore', '');
            commit('cambiarEstadoLoader',false);
            window.location.href = response.data;
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al generar el pago con PSE. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const obtenerFranquiciaTarjeta = async ({commit},creditCardNumber) => {
    if(creditCardNumber.length < 4) return;
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    try{
        await apiQuac.post('/v1/payment/credit-card-info',{creditCardNumber}).then((response) => {
            commit('agregarFranquiciaTarjeta',response.data.scheme);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al obtener la franquicia de la tarjeta. Intentelo mas tarde.');
    }
}

export const pagarCuotaTarjeta = async ({state, commit},infoTarjeta) => {
    if(state.loaderIsActive) return;
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    commit('cambiarEstadoLoader',true);
    const payload = {
        'paymentMethodID': infoTarjeta.paymentMethodID,
        'securityCode': infoTarjeta.securityCode,
        'cardExpirationYear': infoTarjeta.cardExpirationYear,
        'cardholderName': infoTarjeta.cardholderName,
        'docNumber': infoTarjeta.docNumber,
        'cardNumber': infoTarjeta.cardNumber,
        'cardExpirationMonth': infoTarjeta.cardExpirationMonth,
        'docType': infoTarjeta.docType,
        'installments': infoTarjeta.installments,
        'client_document': state.documentoCliente,
        'client_document_type': state.currentTypeDoc,
        'payment_value': state.valorAbono,
        'nit_business': state.comercioCredito.nitBusiness.toString(),
        'invoices': state.idsProntoPago,
        'payment_type': state.idsProntoPago ? '2' : '1',
        'channel_key': state.canal,
        'issuerID': infoTarjeta.issuerID,
        'token': infoTarjeta.token,
        'payment_request_reference': state.payment_request_reference
    };
    try{
        await apiQuac.post(
            '/v1/payment/card',
            payload,
            { headers: { 'X-meli-session-id': window.MP_DEVICE_SESSION_ID } }
        ).then((response) => {
            commit('agregarNitComercio',response.data.order.nitBusiness);
            commit('cambiarDocumentoCliente',response.data.order.documento);
            commit('agregarCelularCliente',response.data.order.celular);
            commit('agregarRespuestaTransaccion', response.data.order);
            state.procesoActual = 'respuesta-transaccion';
            commit('showLogout', null);
            commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al generar el pago con tarjeta. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const obtenerRespuestaBancolombia = async ({commit},payload) => {
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    commit('cambiarEstadoLoader',true);
    try{
        await apiQuac.post('/v1/payment/response-button-bancolombia',
        {transferState: payload.transferState,transferCode: payload.transferCode}
        ).then((response) => {
            commit('agregarNitComercio',response.data.order.nitBusiness);
            commit('cambiarDocumentoCliente',response.data.order.documento);
            commit('agregarCelularCliente',response.data.order.celular);
            commit('agregarRespuestaTransaccion', response.data.order);
            commit('cambiarEstadoLoader',false);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al obtener respuesta de la transacción. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}

export const obtenerRespuestaPayu = async ({commit},referenceCode) => {
    commit('agregarErrors',[]);
    commit('agregarMessageError','');
    commit('cambiarEstadoLoader',true);
    try{
        await apiQuac.post('/v1/payment/response',{referenceCode}).then((response) => {
            commit('agregarNitComercio',response.data.order.nitBusiness);
            commit('cambiarDocumentoCliente',response.data.order.documento);
            commit('agregarCelularCliente',response.data.order.celular);
            commit('cambiarEstadoLoader',false);
            commit('agregarRespuestaTransaccion', response.data.order);
        }).catch((error) => {
            commit('agregarErrors',error.response.data.errors);
            commit('agregarMessageError',error.response.data.message);
            commit('cambiarEstadoLoader',false);
        });
    } catch(error){
        commit('agregarMessageError','Ocurrio un error al obtener respuesta de la transacción. Intentelo mas tarde.');
        commit('cambiarEstadoLoader',false);
    }
}
