import axios from "axios";

const baseURL = 'https://apiportalpagos.quac.co/api'
// const baseURL = 'https://apiportalpagos.devquac.com/api'
// const baseURL = 'http://192.168.209.107:7001/api'

const apiQuac = axios.create({
    baseURL
})

export default apiQuac