import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/style/styles.scss'
import 'bootstrap/dist/js/bootstrap.min.js'
import moment from 'moment'
moment.locale('es');

require('./plugins')

let app = createApp(App)

app.use(store)
app.use(router)

app.mount('#app')
