<template>
    <div class="modal fade center" id="modalUsuarioSinCredito" tabindex="-1" role="dialog" aria-labelledby="modalUsuarioSinCreditoLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="text-center">
              <div class="text-right">
                <img src="@/assets/times-solid.svg" alt="Cerrar" @click="cerrarModal()" style="cursor: pointer;">
              </div>
                <div class="p-3">
                  <figure>
                    <img src="@/assets/advertencia.svg" alt="">
                  </figure>
                  <h2 class="mt-4" style="color:#2E1380;">No tienes cupo...</h2>
                  <p class="mt-3" style="color:#000;">No estás registrado en nuestro sistema con el número de documento <strong v-text="documentoCliente"></strong>.</p>
                  <button class="w-100 text-center btn btn-outline-primary fw-bold mt-4" @click="cerrarModal()">Volver y editar documento</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import $ from 'jquery'
import { mapState } from 'vuex'
export default{
  computed:{
    ...mapState(['documentoCliente'])
  },
  methods:{
    cerrarModal(){
      $("#modalUsuarioSinCredito").modal('hide');
    }
  }
}
</script>
<style lang="scss">
#modalUsuarioSinCredito .modal-content{
  border-radius:16px;
  padding: 1.3rem;
  max-width: 430px;
  margin: 0 auto;
}
</style>